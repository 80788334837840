// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n.ce-news-teaser_eVb8O{\n  position:absolute;\n  right:0px;\n  z-index:50;\n  width:18rem;\n  overflow:hidden;\n  top:75vh\n}\n.inner_654E9{\n  position:relative;\n  width:18rem;\n  --tw-bg-opacity:1;\n  background-color:rgba(0, 0, 0, var(--tw-bg-opacity));\n  --tw-text-opacity:1;\n  color:rgba(255, 255, 255, var(--tw-text-opacity));\n  transition-property:all;\n  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);\n  transition-duration:150ms;\n  transition-duration:300ms\n}\n.content_SMunQ{\n  display:inline-block;\n  padding:1rem;\n  padding-left:2rem\n}\n.toggle_v8vCE{\n  position:absolute;\n  top:0px;\n  bottom:0px;\n  left:0px;\n  width:1.5rem;\n  cursor:pointer\n}\n.open_YbHDK .inner_654E9{\n  transform:translateX(calc(100% - 24px))\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ce-news-teaser": "ce-news-teaser_eVb8O",
	"inner": "inner_654E9",
	"content": "content_SMunQ",
	"toggle": "toggle_v8vCE",
	"open": "open_YbHDK"
};
module.exports = ___CSS_LOADER_EXPORT___;
