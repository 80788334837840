//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CeNewsTeaserNews',
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      status: true
    }
  },
  mounted() {
    this.transitionOut(3000)
  },
  methods: {
    toggle(status) {
      this.status = status | !this.status;
    },
    transitionOut(delay) {
      if(this.status === true) {
        setTimeout(()=> {
          this.status = false;
        }, delay)
      }
    }
  },
}
